@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  li {
    @apply px-4;
    @apply cursor-pointer;
  }
}

body {
  font-family: "Playfair Display", serif;
}

svg {
  color: white;
}

.logo {
  font-family: "Neucha", cursive;
  font-size: 150%;
  color: #ffffff;
}

@media screen and (max-width: 768px) {
  .aboutBox {
    margin-top: -70px;
    margin-bottom: 30px;
  }
}

.content-div {
  background-repeat: no repeat;
  background-size: cover;
  background-position: center;
  height: 250px;
}

.content-div:hover {
  background-image: linear-gradient
    (to right, rgba(112, 157, 255, 0.8) hsla(242, 74%, 61% 0.9)) !important;
}

.container {
  padding: 0.5rem;
}

.contactContainer{
  margin-top: 400px;
}

@media screen and (max-width: 768px) {
  .contactContainer {
    margin-top: 10px;
  }
}

@media screen and (min-width: 768px) {
  .services-list {
    grid-template-columns: repeat(2, 1fr);
    column-gap: 2rem;
  }
}

@media screen and (min-width: 992px) {
  .services-list {
    grid-template-columns: repeat(3, 1fr);
  }
}

